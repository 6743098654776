.active {
  position: relative;
}

.active::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 2px;
  background: #B865C1;
  animation: grow 0.2s ease-in-out both;
}

@keyframes grow {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}