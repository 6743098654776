@keyframes highlight {
  0% {
    box-shadow: unset;
  }
  25% {
    box-shadow: 0px 0px 3px 3px rgb(238, 163, 25);
  }
  50% {
    box-shadow: unset;
  }
  75% {
    box-shadow: 0px 0px 3px 3px rgb(238, 163, 25);
  }
  100% {
    box-shadow: unset;
  }
}

.highlight {
  border-radius: 8px;
  animation: highlight 2s 2 ease-in-out;
}