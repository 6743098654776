.appear {
  animation: appear 0.3s ease-in-out forwards;
}

@keyframes appear {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 200px;
    opacity: 1;
  }
}